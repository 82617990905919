import './App.css';
import AppForm from './components/AppForm/AppForm';

let variableError = false;

function App() {
  if(typeof process.env.REACT_APP_API_URL === 'undefined' || typeof process.env.REACT_APP_MAINTENANCE === 'undefined') {
    variableError = true;
  }
  if (process.env.REACT_APP_MAINTENANCE !== 'true' && variableError === false) {
    return (
      <AppForm />
    );
  } else {
    return <div className="App">
      <div className="App-container">
        <header className="App-header">
          <h1 className="ui header">
            <span>Temporalmente deshabilitado</span>
          </h1>
        </header>
        <content className="App-content"></content>
      </div>
    </div>;
  }
}

export default App;
