import React, { Component } from 'react';
import { Button, Checkbox, Grid, Input, Label, Message } from 'semantic-ui-react';
import AppFormElement from './AppFormElement';

class AppForm extends Component {
    labelWidth = 6
    elementWidth = 10
    state = {}
    notification_timeout = 0;

    // empty values of inputs other than checkbox
    empty_values = {
        'vol_g': '',
        'vol_l': '',
        'water_cut': '',
        'diameter': '',
        'temp': '',
        'pres': '',
        'CO2fraction': '',
        'bicarbonate': '',
        'salinity': ''
    }

    empty_classes = {
        'vol_g': '',
        'vol_l': '',
        'water_cut': '',
        'diameter': '',
        'temp': '',
        'pres': '',
        'CO2fraction': '',
        'bicarbonate': '',
        'salinity': ''
    }

    // checkbox for type of calc linking image classes
    image_options = {
        'norsok': 'petro-image-1',
        'ia': 'petro-image-3'
    }

    // notification colors
    notification_colors = {
        'error': 'red',
        'warning': 'yellow',
        'correct': 'green'
    }

    constructor(props) {
        super(props);
        this.state = {
            calcType: 'ia',
            values: Object.assign({}, this.empty_values),
            class: Object.assign({}, this.empty_classes),
            corrosion: 0,
            criticity: '',
            criticityColor: '',
            header_image_class: this.image_options.ia,
            notification: {
                visible: false,
                color: this.notification_colors.green,
                text: ''
            }
        };
    }

    /*
    * Handles component state from user interaction with the UI
    */
    handleTypeChange = (e, data) => {
        if (data.name === 'calcType') {
            // checkbox multiple for type of calc
            this.setState({
                [data.name]: data.value
            });
            this.setState({
                'header_image_class': this.image_options[data.value]
            });
        } else {
            // other inputs
            var newState = this.state.values;
            newState[data.name] = data.value;
            this.setState({
                'values': newState
            });
        }
    }

    /*
    * Calculate button action
    */
    calculate = () => {
        clearTimeout(this.notification_timeout);
        if (this.validateForm()) {
            fetch(process.env.REACT_APP_API_URL, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    'vol_g': parseFloat(this.state.values.vol_g),
                    'vol_l': parseFloat(this.state.values.vol_l),
                    'diameter': parseFloat(this.state.values.diameter),
                    'temp': parseFloat(this.state.values.temp),
                    'water_cut': parseFloat(this.state.values.water_cut),
                    'pres': parseFloat(this.state.values.pres),
                    'CO2fraction': parseFloat(this.state.values.CO2fraction),
                    'bicarbonate': parseFloat(this.state.values.bicarbonate),
                    'salinity': parseFloat(this.state.values.salinity)
                })
            }).then(res => res.json())
                .then(
                    (result) => {
                        console.table(result);
                        let message;
                        let color = this.notification_colors.correct;

                        if (typeof result.CorrosionRate == 'undefined' || typeof result.Criticidad == 'undefined') {
                            color = this.notification_colors.error;
                            if (typeof result.mensaje != 'undefined') {
                                message = result.mensaje;
                            } else {
                                message = "Error con el cálculo, revise los valores ingresados.";
                            }
                            this.showMessage(message, color);
                        } else {
                            let num = result.CorrosionRate;
                            if (!isNaN(parseFloat(result.CorrosionRate))) {
                                num = parseFloat(result.CorrosionRate).toFixed(2);
                            }
                            this.setState({ 'corrosion': num });
                            this.setState({ 'criticity': result.Criticidad });
                            this.setState({ 'criticityColor': result.Criticidad });
                        }
                    },
                    (error) => {
                        this.showMessage('Error: ' + JSON.stringify(error), this.notification_colors.error);
                    }
                )
        }
    }

    validateForm = () => {
        let allValid = true;
        var newState = this.state.class;
        let message = "";
        let empty = true;
        for (const [key, value] of Object.entries(this.state.values)) {
            let numb;
            let valid = true;
            if (!isNaN(parseFloat(value))) {
                numb = parseFloat(value);
                switch (key) {
                    case 'vol_g':
                        if (numb < 0 || numb > 30000) {
                            valid = false;
                            message += "Flujo volumétrico de gas debe estar entre 1 y 30000\n";
                        }
                        break;
                    case 'vol_l':
                        if (numb < 0 || numb > 30000) {
                            valid = false;
                            message += "Flujo volumétrico de líquido debe estar entre 1 y 30000\n";
                        }
                        break;
                    case 'water_cut':
                        if (numb < 0 || numb > 100) {
                            valid = false;
                            message += "Contenido de agua en el fluido debe estar entre 0 y 100\n";
                        }
                        break;
                    case 'diameter':
                        if (numb <= 0) {
                            valid = false;
                            message += "Diámetro interno de la tubería debe ser mayor a 0\n";
                        }
                        break;
                    case 'temp':
                        if (numb < 68 || numb > 302) {
                            valid = false;
                            message += "Temperatura del fluido en la cabeza del pozo debe estar entre 68 y 302\n";
                        }
                        break;
                    case 'pres':
                        if (numb < 14.5 || numb > 14500) {
                            valid = false;
                            message += "Presión del fluido en la cabeza del pozo debe estar entre 14.5 y 14500\n";
                        }
                        break;
                    case 'CO2fraction':
                        if (numb < 0 || numb > 1) {
                            valid = false;
                            message += "Fracción molar de CO2 en el gas debe estar entre 0 y 1\n";
                        }
                        break;
                    case 'bicarbonate':
                        if (numb < 0 || numb > 20000) {
                            valid = false;
                            message += "Contenido de bicarbonatos en el agua de formacion debe estar entre 0 y 20000\n";
                        }
                        break;
                    case 'salinity':
                        if (numb < 0 || numb > 175000) {
                            valid = false;
                            message += "Salinidad del agua de formación debe estar entre 0 y 175000\n";
                        }
                        break;
                }
            } else {
                valid = false;
                if (empty) {
                    message += "Se deben ingresar todos los valores\n";
                    empty = false;
                }
            }

            // change state: class
            if (valid) {
                newState[key] = '';
            } else {
                newState[key] = 'error';
                allValid = false;
            }
        }
        this.setState({
            'class': newState
        });
        if (!allValid) {
            this.showMessage(message, this.notification_colors.error);
        }
        return allValid;
    }

    showMessage = (message, color) => {
        var newState = this.state.notification;
        newState.text = message;
        newState.visible = true;
        newState.color = color;
        this.setState({
            'notification': newState
        });
        this.notification_timeout = setTimeout(() => {
            var newState = this.state.notification;
            newState.visible = false;
            this.setState({
                'notification': newState
            });
        }, 4000)
    }

    /*
    * Clear button action
    */
    clean = () => {
        this.setState({ values: Object.assign({}, this.empty_values) });
        this.setState({ 'corrosion': 0 });
        this.setState({ 'criticity': '' });
        this.setState({ 'criticityColor': '' });
        this.setState({ class: Object.assign({}, this.empty_classes) });
        this.handleDismiss();
    }

    /*
    * Handle notification
    */
    handleDismiss = () => {
        var newState = this.state.notification;
        newState.visible = false;
        this.setState({
            'notification': newState
        });
    }

    render() {
        return (
            <div className="App-container">
                <div className="App" >
                    <Message
                        hidden={!this.state.notification.visible}
                        floating={true}
                        color={this.state.notification.color}
                        onDismiss={this.handleDismiss}
                        header='Error de Cálculo'
                        content={this.state.notification.text}
                    />
                    <header className="App-header">
                        <h1 className="ui header">
                            <div className={`header-image ${this.state.header_image_class}`}></div>
                            <span>Cálculo de Tasa de Corrosión</span>
                        </h1>
                        <div className="survey"><a href="https://docs.google.com/forms/d/e/1FAIpQLSdpYQx7adKrJKSCDU1DarN2GhgNDLBNstRfnlQp3qsxlmfIcg/viewform?gxids=7628" target="_blank">Encuesta sobre Inteligencia Articial aplicada en su empresa. Click aquí!</a></div>
                    </header>
                    <content className="App-content">
                        <Grid verticalAlign="middle">
                            {/*
                            <Grid.Row className="check-box-row">
                                <Grid.Column width={2} />
                                <Grid.Column width={5}>
                                    <Checkbox
                                        radio
                                        label='Norsok'
                                        name='calcType'
                                        value='norsok'
                                        checked={this.state.calcType === 'norsok'}
                                        onChange={this.handleTypeChange} />
                                </Grid.Column>
                                <Grid.Column width={5}>
                                    <Checkbox
                                        radio
                                        label='Inteligencia Artificial'
                                        name='calcType'
                                        value='ia'
                                        checked={this.state.calcType === 'ia'}
                                        onChange={this.handleTypeChange} />
                                </Grid.Column>
                            </Grid.Row>
                            */}
                            <AppFormElement
                                label="Flujo volumétrico de gas"
                                labelWidth={this.labelWidth}
                                inputName="vol_g"
                                inputWidth={this.elementWidth}
                                inputLabel="MSCF"
                                value={this.state.values.vol_g}
                                clickHandler={this.handleTypeChange}
                                class={this.state.class.vol_g} />
                            <AppFormElement
                                label="Flujo volumétrico de líquido"
                                labelWidth={this.labelWidth}
                                inputName="vol_l"
                                inputWidth={this.elementWidth}
                                inputLabel="BFPD"
                                value={this.state.values.vol_l}
                                clickHandler={this.handleTypeChange}
                                class={this.state.class.vol_l} />
                            <AppFormElement
                                label="Contenido de agua en el fluido"
                                labelWidth={this.labelWidth}
                                inputName="water_cut"
                                inputWidth={this.elementWidth}
                                inputLabel="%"
                                value={this.state.values.water_cut}
                                clickHandler={this.handleTypeChange}
                                class={this.state.class.water_cut} />
                            <AppFormElement
                                label="Diámetro interno de la tubería"
                                labelWidth={this.labelWidth}
                                inputName="diameter"
                                inputWidth={this.elementWidth}
                                inputLabel="in"
                                value={this.state.values.diameter}
                                clickHandler={this.handleTypeChange}
                                class={this.state.class.diameter} />
                            <AppFormElement
                                label="Temperatura"
                                labelWidth={this.labelWidth}
                                inputName="temp"
                                inputWidth={this.elementWidth}
                                inputLabel="F"
                                value={this.state.values.temp}
                                clickHandler={this.handleTypeChange}
                                class={this.state.class.temp} />
                            <AppFormElement
                                label="Presión"
                                labelWidth={this.labelWidth}
                                inputName="pres"
                                inputWidth={this.elementWidth}
                                inputLabel="psi"
                                value={this.state.values.pres}
                                clickHandler={this.handleTypeChange}
                                class={this.state.class.pres} />
                            <AppFormElement
                                label="Fracción molar de CO2 en el gas"
                                labelWidth={this.labelWidth}
                                inputName="CO2fraction"
                                inputWidth={this.elementWidth}
                                inputLabel="-"
                                value={this.state.values.CO2fraction}
                                clickHandler={this.handleTypeChange}
                                class={this.state.class.CO2fraction} />
                            <AppFormElement
                                label="Contenido de bicarbonatos en el agua de formación"
                                labelWidth={this.labelWidth}
                                inputName="bicarbonate"
                                inputWidth={this.elementWidth}
                                inputLabel="ppm"
                                value={this.state.values.bicarbonate}
                                clickHandler={this.handleTypeChange}
                                class={this.state.class.bicarbonate} />
                            <AppFormElement
                                label="Salinidad del agua de formación"
                                labelWidth={this.labelWidth}
                                inputName="salinity"
                                inputWidth={this.elementWidth}
                                inputLabel="ppm"
                                value={this.state.values.salinity}
                                clickHandler={this.handleTypeChange}
                                class={this.state.class.salinity} />
                            <Grid.Row>
                                <Grid.Column width={4} className="btn-row-left-space-column" />
                                <Grid.Column width={4}>
                                    <Button primary
                                        onClick={this.calculate}
                                    >Calcular</Button>
                                </Grid.Column>
                                <Grid.Column width={4}>
                                    <Button
                                        onClick={this.clean}
                                    >Limpiar</Button>
                                </Grid.Column>
                                <Grid.Column width={4} />
                            </Grid.Row>
                            <Grid.Row className="total-row result-row">
                                <Grid.Column width={5} className="total-row-label">
                                    <div className="ui form-label">Resultado</div>
                                </Grid.Column>
                                <Grid.Column width={10}>
                                    <Input className="total-btn" disabled size="large" labelPosition='right'>
                                        <Label basic className="total-long-label">Tasa de corrosión</Label>
                                        <input value={this.state.corrosion} />
                                        <Label basic className="total-unit-label">mpy</Label>
                                    </Input>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row className="total-row criticity-row">
                                <Grid.Column width={5} className="total-row-label">
                                    <div className="ui form-label">Criticidad</div>
                                </Grid.Column>
                                <Grid.Column width={10}>
                                    <Input className={"total-btn " + this.state.criticityColor} disabled size="large">
                                        <input value={this.state.criticity} />
                                    </Input>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </content>
                </div>
                {/* Required links because of the used icons in the page */}
                <div className="App-footer">
                    <div>Icons made by <a target="_blank" rel="noreferrer" href="http://www.freepik.com/" title="Freepik">Freepik</a> from <a target="_blank" rel="noreferrer" href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a></div>
                </div>
            </div>
        );
    }
}

export default AppForm;