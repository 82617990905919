import React, { Component } from 'react';
import { Grid, Input } from 'semantic-ui-react';

class AppFormElement extends Component {
    render() {
        return (
            <Grid.Row>
                <Grid.Column width={this.props.labelWidth}>
                    <div className="ui form-label {this.props.class}">{this.props.label}</div>
                </Grid.Column>
                <Grid.Column width={this.props.inputWidth}>
                    <Input
                        className={this.props.class}
                        name={this.props.inputName}
                        size="large"
                        label={{ basic: true, content: this.props.inputLabel }}
                        labelPosition='right'
                        value={this.props.value}
                        onChange={this.props.clickHandler}
                    />
                </Grid.Column>
            </Grid.Row>
        );
    }
}

export default AppFormElement;